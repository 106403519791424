import {
  CREATE_NEW_SSHEPHERD_GROUP_START,
  CREATE_NEW_SSHEPHERD_GROUP,
  CREATE_NEW_SSHEPHERD_USER,
  DELETE_SSHEPHERD_USER,
  DELETE_SSHEPHERD_HOST,
  DELETE_SSHEPHERD_GROUP,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TASK_LIST,
  GET_SSHEPHERD_SESSION_LIST,
  GET_SSHEPHERD_SESSION_DETAIL,
  GET_SSHEPHERD_SESSION_CHARTS,
  GET_SSHEPHERD_DASHBOARD_LIST,
  GET_SSHEPHERD_DEVICE_LIST,
  GET_SSHEPHERD_ROLE_LIST,
  GET_SSHEPHERD_USER_LIST,
  GET_SSHEPHERD_USER_ROLES,
  GET_SSHEPHERD_ROLE_USERS,
  GET_SSHEPHERD_GROUP_LIST,
  SET_SSHEPHERD_SELECTED_SESSION,
  SET_SSHEPHERD_SELECTED_GROUP,
  SET_SSHEPHERD_SELECTED_ROLE,
  SET_SSHEPHERD_SELECTED_USER,
  SET_SSHEPHERD_SELECTED_HOST,
  SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION,
  SHOW_MESSAGE,
  SET_SSHEPHERD_SESSION_TIME_FILTER,
  SET_SSHEPHERD_SESSION_TIMERANGE_FILTER,
  SET_SSHEPHERD_SESSION_TYPE_FILTER,
  SET_SSHEPHERD_SESSION_LIVE_FILTER,
  ATTACH_SSHEPHERD_LIVE_SESSION,
  SSHEPHERD_LIVE_SESSION_UPDATED,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO,
  SSHEPHERD_REFRESH_TIMER,
  GET_SSHEPHERD_AUTH_PROVIDER,
  GET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_DETECTING_AUTH_PROVIDER,
  RESET_SSHEPHERD_STATE,
  SIGNOUT_AUTH_SUCCESS,
  GET_SSHEPHERD_LICENSE_CONFIG,
  SET_SSHEPHERD_LICENSE_CONFIG,
  GET_SSHEPHERD_REGISTRATION_KEYS,
  SET_SSHEPHERD_SELECTED_REGISTRATION_KEY,
  GENERATE_SSHEPHERD_REGISTRATION_KEY,
  DELETE_SSHEPHERD_REGISTRATION_KEY,
  CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY,
  SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN,
} from "../../shared/constants/ActionTypes";
import {
  authProviders,
  defaultAuthConfig,
  ssoConfig,
} from "../../shared/constants/AppConst";
import Api from "../../@crema/services/ApiConfig";
import { appIntl } from "../../@crema/utility/Utils";
import moment from "moment";
import io from "socket.io-client";
import {
  onJWTAuthSignout,
  onSAMLAuthSignout,
  onOIDCAuthSignout,
} from "../actions";

var hasInitialData = false;

export const onDetectAuthProvider = () => {
  return (dispatch) => {
    dispatch({ type: SET_SSHEPHERD_DETECTING_AUTH_PROVIDER, payload: true });

    Api.get("/sso", {})
      .then((result) => {
        if (result.status === 200) {
          let authType = result.data.auth_type;
          dispatch({ type: GET_SSHEPHERD_AUTH_PROVIDER, payload: authType }); // clear out any previous detail
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    dispatch({ type: SET_SSHEPHERD_DETECTING_AUTH_PROVIDER, payload: false });
  };
};

export const forceStandardLogin = () => {
  return (dispatch) => {
    dispatch({ type: SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN, payload: true });
  };
};

export const onGetSessionList = () => {
  // Do we have a valid token, if not, ignore.  Sessions view is the default view, and
  // an attempt is made to load sessions view.  We want to avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-recording")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  let startDate = new Date("2020-01-01T00:00:00"); // All Sessions
  //let startDate = new Date();
  //startDate.setDate(startDate.getDate()-30); // 30 days ago

  return (dispatch) => {
    //dispatch({ type: FETCH_START });
    Api.get("/api/recording", {
      params: {
        start: formatDateTimeForAPI(startDate),
        end: formatDateTimeForAPI(new Date()),
      },
    })
      .then((result) => {
        if (result.status === 200) {
          if (result && result.data && result.data.recordings) {
            result.data.recordings.map((row) => {
              row.isLive = row.ended_on ? false : true;
              row.Status = row.isLive ? "Live" : "Finished";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              if (row.started_on && row.started_on.slice(-1) != "Z")
                row.started_on += "Z";
              if (row.ended_on && row.ended_on.slice(-1) != "Z")
                row.ended_on += "Z";

              row.StartedOnX = moment(row.started_on).unix();
              row.EndedOnX = moment(row.ended_on).unix();
              row.FormattedStartTime = moment
                .utc(row.started_on)
                .local()
                .format("L LT");
              row.FormattedEndTime = row.ended_on
                ? moment.utc(row.ended_on).local().format("L LT")
                : "-";
              row.Duration = row.ended_on
                ? moment(row.ended_on).diff(row.started_on) // use ended time
                : moment.utc().diff(moment.utc(row.started_on)); // use time from now

              let dur = moment.duration(row.Duration);
              row.FormattedDuration =
                (row.ended_on ? "" : "+") + // show plus sign for live sessions
                ((dur.years() > 0 ? dur.years() + "Y " : "") +
                  (dur.months() > 0 ? dur.months() + "M " : "") +
                  (dur.days() > 0 ? dur.days() + "d " : "") +
                  (dur.hours() > 0 ? dur.hours() + "h " : "") +
                  (dur.minutes() > 0 ? dur.minutes() + "m " : "") +
                  dur.seconds() +
                  (row.ended_on ? "." + dur.milliseconds() : "") +
                  "s");

              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }

          dispatch(onCalculateSessionCharts(result.data.recordings));
          //dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_SESSION_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDashboardSessionList = () => {
  // Do we have a valid token, if not, ignore.  Sessions view is the default view, and
  // an attempt is made to load sessions view.  We want to avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  //let startDate = new Date("2020-01-01T00:00:00")) // All Sessions
  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 30); // 30 days ago

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/agent/dashboard", {
      params: {
        start: formatDateTimeForAPI(startDate),
        end: formatDateTimeForAPI(new Date()),
      },
    })
      .then((result) => {
        if (result.status === 200) {
          //console.log("dashboard result: ", result);

          if (result && result.data && result.data.recordings) {
            result.data.recordings.map((row) => {
              row.isLive = row.ended_on ? false : true;
              row.Status = row.isLive ? "Live" : "Finished";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              row.FormattedStartTime = moment
                .utc(row.started_on)
                .local()
                .format("L LT");
              row.FormattedEndTime = row.ended_on
                ? moment.utc(row.ended_on).local().format("L LT")
                : "-";
              row.Duration = row.ended_on
                ? moment(row.ended_on).diff(row.started_on) // use ended time
                : moment.utc().diff(moment.utc(row.started_on)); // use time from now

              let dur = moment.duration(row.Duration);
              row.FormattedDuration =
                (row.ended_on ? "" : "+") + // show plus sign for live sessions
                ((dur.years() > 0 ? dur.years() + "Y " : "") +
                  (dur.months() > 0 ? dur.months() + "M " : "") +
                  (dur.days() > 0 ? dur.days() + "d " : "") +
                  (dur.hours() > 0 ? dur.hours() + "h " : "") +
                  (dur.minutes() > 0 ? dur.minutes() + "m " : "") +
                  dur.seconds() +
                  (row.ended_on ? "." + dur.milliseconds() : "") +
                  "s");

              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }

          //console.log("GET_SSHEPHERD_DASHBOARD_LIST");
          //console.dir(result.data);

          dispatch({ type: FETCH_SUCCESS });
          // dispatch(onCalculateSessionCharts(result.data.recordings));
          dispatch({
            type: GET_SSHEPHERD_DASHBOARD_LIST,
            payload: result.data,
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCalculateSessionCharts = (sessions) => {
  if (!sessions || sessions.length < 1) return (dispatch) => {};

  return (dispatch) => {
    // Sort Ascending for calculation of time buckets
    let sortField = "started_on";
    let sortedSessions = sessions.sort((a, b) => {
      let result = 0;
      if (!a[sortField]) result = -1;
      if (!b[sortField]) result = 1;

      if (result == 0) {
        let aUpper = a[sortField].toUpperCase();
        let bUpper = b[sortField].toUpperCase();
        if (aUpper > bUpper) result = 1;
        if (aUpper < bUpper) result = -1;
      }
      return result; // * -1; // *-1 for descending, otherwise *1 for ascending
    });

    let timeNow = moment.utc(); // use timeNow for calculating differences, but use moment() / moment.utc() elsewhere to avoid corrupting timeNow, especially using endOf()

    let past24Hours = [];
    for (let n = 0; n < 24; n++)
      past24Hours.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "hours"),
        Label: n == 0 ? "Now" : n == 1 ? n + " hour ago" : n + " hours ago", // + moment(timeNow).subtract(n + 1, "hours").local().format("L LT"),
        Duration: "hours",
      });

    let pastWeek = [];
    for (let n = 0; n < 7; n++)
      pastWeek.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "days"),
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago", // + moment(timeNow).subtract(n, "days").local().format("L LT"),
        Duration: "days",
      });

    let pastMonth = [];
    for (let n = 0; n < 30; n++)
      pastMonth.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "days"),
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago", // + moment(timeNow).subtract(n, "days").local().format("L LT"),
        Duration: "days",
      });

    let pastAll = [];
    let firstSessionStarted = moment.utc(sortedSessions[0].started_on);
    let firstSessionStartDaysDiff = timeNow.diff(firstSessionStarted, "days");
    for (let n = 0; n <= firstSessionStartDaysDiff; n++)
      pastAll.push({
        Sessions: 0,
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago",
        Start: moment(timeNow).subtract(n + 1, "days"),
        Duration: "days",
      });

    let timeStart = null;
    let timeEnd = null;
    for (let h = 0; h < 24; h++) {
      timeEnd = moment(timeNow).utc().subtract(h, "hours").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(h + 1, "hours")
        .unix();
      past24Hours[h].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < 7; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastWeek[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < 30; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastMonth[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < pastAll.length; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastAll[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    timeStart = moment(timeNow).utc().subtract(24, "hours").unix();
    let totalPast24Hours = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    //timeStart = moment(timeNow).startOf('day').utc().subtract(6, 'days').format();
    timeStart = moment(timeNow).startOf("day").utc().subtract(6, "days").unix();
    let totalPastWeek = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    timeStart = moment(timeNow)
      .startOf("day")
      .utc()
      .subtract(29, "days")
      .unix();
    let totalPastMonth = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    let totalAll = sortedSessions ? sortedSessions.length : 0;

    dispatch({
      type: GET_SSHEPHERD_SESSION_CHARTS,
      payload: {
        past24Hours: past24Hours.reverse(),
        totalPast24Hours: totalPast24Hours,
        pastWeek: pastWeek.reverse(),
        totalPastWeek: totalPastWeek,
        pastMonth: pastMonth.reverse(),
        totalPastMonth: totalPastMonth,
        pastAll: pastAll.reverse(),
        totalAll: totalAll,
      },
    });
  };
};

export const onSetSessionTimeFilter = (filter) => {
  let timeFilterStart = null;
  switch (filter) {
    case 0: // Past 24 Hours
      timeFilterStart = moment().subtract(24, "hours");
      break;

    case 1: // Past 7 Days
      timeFilterStart = moment().endOf("day").subtract(6, "days");
      break;

    case 2: // Past 30 Days
      timeFilterStart = moment().endOf("day").subtract(29, "days");
      break;

    default:
      timeFilterStart = moment("2001-01-01T00:00"); // beginning of time that sessions could have been recorded with SSHepherd
  }

  localStorage.setItem("sessionTimeFilter", filter);

  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TIME_FILTER,
      payload: { timeFilter: filter, startTime: timeFilterStart.utc().unix() },
    });
  };
};

export const onSetSessionTimeRangeFilter = (startTime, duration) => {
  //let timeFilterStart = moment.utc(startTime).format();
  let timeFilterStart = moment(startTime).utc().unix();

  //moment.utc(row.ended_on).local().format("L LT")
  let timeFilterEnd = moment(startTime).add(1, duration).utc().unix();

  //localStorage.setItem("sessionTimeRangeFilter", filter);
  //console.log("timeFilterStart", timeFilterStart);
  //console.log("timeFilterEnd", timeFilterEnd);
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TIMERANGE_FILTER,
      payload: { startTime: timeFilterStart, endTime: timeFilterEnd },
    });
  };
};

export const onSetSessionTypeFilter = (sessionType) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TYPE_FILTER,
      payload: { sessionType: sessionType },
    });
  };
};

export const onSetSessionLiveFilter = (isLive) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_LIVE_FILTER,
      payload: { isLive: isLive },
    });
  };
};

export const onGetSessionDetail = (sessionId) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: GET_SSHEPHERD_SESSION_DETAIL, payload: null }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    Api.get("/api/recording/" + sessionId + "/data", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          dispatch({
            type: GET_SSHEPHERD_SESSION_DETAIL,
            payload: result.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAttachLiveSession = (session) => {
  return (dispatch) => {
    let liveSessionURL = Api.defaults.baseURL.replace("api/", ""); // trim off the ending
    const token = localStorage.getItem("token");

    let liveSocket = io(liveSessionURL, {
      query: { auth_token: token },
      auth: (cb) => {
        cb(token);
      },
      query: { auth_token: token },
      extraHeaders: {
        "Authentication-Token": token,
        "X-ET-Ingress-Tag": session.host.id,
      },
      //transports: ['websocket'],//, 'polling'],
    });

    // client-side
    liveSocket.on("error", (err) => {
      console.log(err); // { content: "Please retry later" }
    });

    /*
    liveSocket.onAny((event, ...args) => {
      console.log("liveSocket event: ", event);
      console.log("liveSocket event args: ", args);
    });*/

    liveSocket.on("disconnect", () => {
      console.log("socket disconnected: ", liveSocket.id);
    });

    // Establish Connection
    liveSocket.on("connect", () => {
      //console.log("socket connect: ", liveSocket.id);

      let attachmentConfig = { recording_id: session.id, replay: true }; // true = replay with new data, false is just new data only
      //console.log("...Establishing new_record_attach: ", attachmentConfig);
      liveSocket.emit("new_record_attach", attachmentConfig);
    });

    //console.log("...Subscribing to 'record_out' event");
    liveSocket.on("record_out", (data) => {
      //console.log("record_out event: ", data);
      dispatch({
        type: SSHEPHERD_LIVE_SESSION_UPDATED,
        payload: { socketData: data },
      });
    });

    liveSocket.on("connect_error", (data) => {
      console.log("connect_error: ", data);
    });

    liveSocket.connect();

    dispatch({
      type: ATTACH_SSHEPHERD_LIVE_SESSION,
      payload: { sessionId: session.id, liveSessionSocket: liveSocket },
    });
  };
};

export const onGetHosts = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/agent", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          /*
          if( result && result.data && result.data.agents ){
            result.data.agents.map((row) => {
              //row.Status = row.ended_on ? "Finished" : "Live";
            });
          }*/

          let sortField = "hostname";
          let sortedItems = [];
          if (result.data && result.data.agents) {
            sortedItems = result.data.agents.sort((a, b) => {
              let result = 0;
              let aUpper = a[sortField].toUpperCase();
              let bUpper = b[sortField].toUpperCase();
              if (aUpper > bUpper) result = 1;
              if (aUpper < bUpper) result = -1;
              return result; // * -1; // use *1 or *-1 to alternate ascending and descending
            });
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_DEVICE_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetRoles = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-user-role")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/role", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          /*
          if( result && result.data && result.data.recordings ){
            result.data.recordings.map((row) => {
              row.Status = row.ended_on ? "Finished" : "Live";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              row.FormattedStartTime = new Date(row.started_on).toLocaleString();
              row.FormattedEndTime = row.ended_on ? new Date(row.ended_on).toLocaleString() : "-";
              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }
          */
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_ROLE_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetUsers = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/user", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          /*
        if( result && result.data && result.data.recordings ){
          result.data.recordings.map((row) => {
            row.Status = row.ended_on ? "Finished" : "Live";
              row.SessionType = row.has_video ? "RDP" : "Terminal";
            row.FormattedStartTime = new Date(row.started_on).toLocaleString();
            row.FormattedEndTime = row.ended_on ? new Date(row.ended_on).toLocaleString() : "-";
            row.Hostname = row.host ? row.host.hostname : null;
            row.Username = row.user ? row.user.email : null;
          });
        }
        */

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_USER_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetGroups = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-group")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/group", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          /*
          if( result && result.data && result.data.groups ){
            result.data.groups.map((row) => {
              row.Status = row.ended_on ? "Finished" : "Live";
            });
          } */
          let sortField = "name";
          let sortedGroups = [];
          if (result.data && result.data.groups) {
            sortedGroups = result.data.groups.sort((a, b) => {
              let result = 0;
              let aUpper = a[sortField].toUpperCase();
              let bUpper = b[sortField].toUpperCase();
              if (aUpper > bUpper) result = 1;
              if (aUpper < bUpper) result = -1;
              return result; // * -1; // use *1 or *-1 to alternate ascending and descending
            });
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_GROUP_LIST,
            payload: { groups: sortedGroups },
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAddGroup = (groupName) => {
  if (!groupName) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_SSHEPHERD_GROUP_START });
    Api.post("/api/group", {
      name: groupName,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: CREATE_NEW_SSHEPHERD_GROUP, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["group.created"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.includes("E11000")
        ) {
          alert(
            "That group name is already in use.  Please use a different name."
          );
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteGroup = (group) => {
  if (!group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/group/" + group.name, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_GROUP, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["group.Deleted"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAddUser = (user) => {
  if (!user.email && !user.password) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.post("/api/user", {
      email: user.email,
      password: user.password,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.created"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        //dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("error: ", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.includes("E11000")
        ) {
          alert("The user you are trying to add has already been added.");
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onEditUser = (user) => {
  if (!user.password || !user.newPassword || !user.newPasswordConfirm)
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.post("/api/user/password", {
      password: user.password,
      new_password: user.newPassword,
      new_password_confirm: user.newPasswordConfirm,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.Saved"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        //dispatch({ type: FETCH_ERROR, payload: error.message });
        try {
          if (error.response.data.response.errors.password)
            error.response.data.response.errors.password.forEach((pe) => {
              dispatch({ type: SHOW_MESSAGE, payload: pe });
            });
          else
            dispatch({
              type: SHOW_MESSAGE,
              payload: messages["message.somethingWentWrong"],
            });
        } catch (ex) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["message.somethingWentWrong"],
          });
        }
      });
  };
};

export const onDeleteUser = (user) => {
  if (!user.email) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/user/" + user.email, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_USER, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.Deleted"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAssignUsersToGroup = (users, group) => {
  if (!users || users.length < 1 || !group || !group.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/group/" + group.name + "/users", {
      users: users,
    })
      .then((result) => {
        if (result.status === 200) {
          //dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.AddedToGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onRemoveUserFromGroup = (user, group) => {
  if (!user || !user.email || !group || !group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    /*Api.delete('/api/user/' + user.email, {
      email: user.email,
    })*/
    Api.delete("/api/group/" + group.name + "/user/" + user.email, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.RemovedFromGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteHost = (host) => {
  if (!host.id) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/agent/" + host.id, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_HOST, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.Deleted"],
          });

          dispatch(onGetHosts());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAssignAgentsToGroup = (agentIds, group) => {
  if (agentIds == null || agentIds.length < 1 || !group || !group.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/group/" + group.name + "/agents", {
      agents: agentIds,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.AddedToGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onRemoveAgentFromGroup = (agent, group) => {
  if (!agent || !agent.id || !group || !group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/group/" + group.name + "/agent/" + agent.id, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.RemovedFromGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetUserRoles = (email) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/user/" + email + "/role", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          /*
          if( result && result.data && result.data.roles ){
            result.data.roles.map((row) => {
              //row.Status = row.ended_on ? "Finished" : "Live";
            });
          } */

          dispatch({
            type: GET_SSHEPHERD_USER_ROLES,
            payload: { userEmail: email, userRoles: result.data },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetRoleUsers = (rolename) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/role/" + rolename, {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          // walk through each user in the array and build a new array having email property (for UI binding)
          let formattedResult = [];
          if (
            result &&
            result.data &&
            result.data.role &&
            result.data.role.users
          ) {
            result.data.role.users.map((row) => {
              formattedResult.push({ email: row });
            });
          }

          dispatch({
            type: GET_SSHEPHERD_ROLE_USERS,
            payload: { rolename: rolename, roleUsers: formattedResult },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAssignUsersToRole = (users, role) => {
  if (!users || users.length < 1 || !role || !role.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/role/" + role.name + "/users", {
      users: users,
    })
      .then((result) => {
        if (result.status === 200) {
          //dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.AddedToGroup"],
          });

          dispatch(onGetRoleUsers(role.name));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        //dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("error: ", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.includes("E11000")
        ) {
          alert(
            "The user you are trying to include has already been included."
          );
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onRemoveUserFromRole = (user, role) => {
  if (!user || !user.email || !role || !role.name) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/user/" + user.email + "/role/" + role.name, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.RemovedFromGroup"],
          });

          dispatch(onGetRoleUsers(role.name));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onTerminateSession = (session) => {
  if (!session || !session.host || !session.host.id) return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    Api.delete("/api/tunnel/" + session.id, {
      headers: {
        //'Authentication-Token': token,
        "X-ET-Ingress-Tag": session.host.id,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["session.SessionTerminated"],
          });

          dispatch(onGetSessionList());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

const formatDateTimeForAPI = (dateTime) => {
  //return dateTime != null ? dateTime.toISOString().replace('Z', '') : null;
  return dateTime != null
    ? new Date(dateTime).toISOString().replace("Z", "")
    : null;
};

export const onSetSelectedSession = (session) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION,
      payload: session,
    });
  };
};

export const onShowSelectedSessionDetail = (show) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL,
      payload: show,
    });
  };
};

export const onShowSelectedSessionVideo = (show) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO,
      payload: show,
    });
  };
};

export const onSetSelectedGroup = (group) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_GROUP,
      payload: group,
    });
  };
};

export const onSetSelectedRole = (role) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_ROLE,
      payload: role,
    });
  };
};

export const onSetSelectedUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_USER,
      payload: user,
    });
  };
};

export const onSetSelectedHost = (host) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_HOST,
      payload: host,
    });
  };
};

export const onSetSelectedViewDescription = (viewDescription) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION,
      payload: viewDescription,
    });
  };
};

export const onSetSelectedRegistrationKey = (token) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_REGISTRATION_KEY,
      payload: token,
    });
  };
};

export const onTimerElapsed = () => {
  return (dispatch) => {
    dispatch({
      type: SSHEPHERD_REFRESH_TIMER,
      payload: null,
    });
  };
};

export const onGetSSOConfig = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    //dispatch({ type: GET_SSHEPHERD_AUTH_CONFIG, payload: defaultAuthConfig }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    let authConfig = null;
    Api.get("/sso/config", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          authConfig = result.data;

          // use defaults if no values yet
          let ss = Object.keys(authConfig?.saml_config);
          if (Object.entries(authConfig?.saml_config).length < 1) {
            // saml_config has no properties, use defaults
            console.log(
              "setting default saml config",
              defaultAuthConfig.saml_config
            );
            authConfig.saml_config = defaultAuthConfig.saml_config;
          }
          if (Object.keys(authConfig?.oidc_config).length < 1) {
            // oidc_config has no properties, use defaults
            console.log(
              "setting default oidc config",
              defaultAuthConfig.oidc_config
            );
            authConfig.oidc_config = defaultAuthConfig.oidc_config;
          }
          if (Object.keys(authConfig?.ldap_config).length < 1) {
            // ldap_config has no properties, use defaults
            console.log(
              "setting default ldap config",
              defaultAuthConfig.ldap_config
            );
            authConfig.ldap_config = defaultAuthConfig.ldap_config;
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_AUTH_CONFIG, payload: authConfig });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSaveAuthConfig = (authConfig) => {
  console.log("onSaveAuthConfig: full config", authConfig);

  const { messages } = appIntl();
  return (dispatch) => {
    //dispatch({ type: SET_SSHEPHERD_AUTH_CONFIG, payload: defaultAuthConfig }); // clear out any previous detail

    dispatch({ type: FETCH_START });
    Api.post("/sso/config", authConfig)
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SSHEPHERD_AUTH_CONFIG,
            payload: authConfig,
          });
          dispatch(onGetSSOConfig()); // refresh
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetLicenseConfig = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: GET_SSHEPHERD_LICENSE_CONFIG, payload: {} }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    let licenseConfig = null;
    Api.get("/api/license", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          licenseConfig = result.data;
          let expiration = (licenseConfig.expiresFormatted = moment
            .utc(licenseConfig.expires)
            .local());
          licenseConfig.expiresFormatted = expiration.format("L LT");
          licenseConfig.isExpired = expiration.isBefore(moment());
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_LICENSE_CONFIG,
            payload: licenseConfig,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSaveLicenseConfig = (licenseConfig) => {
  console.log("onSaveLicenseConfig: ", licenseConfig);

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: SET_SSHEPHERD_LICENSE_CONFIG, payload: {} }); // clear out any previous detail
    //dispatch({ type: FETCH_START });

    //let authConfig = null;
    Api.post("/api/license", licenseConfig)
      .then((result) => {
        if (result.status === 200) {
          //dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SSHEPHERD_LICENSE_CONFIG,
            payload: licenseConfig,
          });
          dispatch(onGetLicenseConfig()); // refresh
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetRegistrationKeys = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/apikey", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          let rows = result.data?.keys;
          rows.forEach((row) => {
            row.FormattedCreationTimestamp = moment
              .utc(row.created)
              .local()
              .format("L LT");
          });

          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_REGISTRATION_KEYS,
            payload: rows,
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGenerateRegistrationKey = (comment, permissions) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.post("/api/apikey", {
      params: { description: comment, permissions: permissions },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GENERATE_SSHEPHERD_REGISTRATION_KEY,
            payload: result.data,
          });
          dispatch(onGetRegistrationKeys()); // refresh the list
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteRegistrationToken = (uuid) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.delete("/api/apikey/" + uuid, {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: DELETE_SSHEPHERD_REGISTRATION_KEY,
            payload: result.data.keys,
          });
          dispatch(onGetRegistrationKeys()); // refresh the list
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const clearGeneratedRegistrationKey = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY,
      payload: null,
    });
  };
};

export const clearCookies = () => {
  console.log("clearCookies");
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split("=");
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    var expires = ";expires=" + d;
    var name = cookiename;
    //alert(name);
    var value = "";
    document.cookie = name + "=" + value + expires + "; path=/acc/html";
  }
  if (cookies.length < 1 || (cookies.length == 1 && cookies[0] == "")) {
  } else window.location = ""; // TO REFRESH THE PAGE
};
