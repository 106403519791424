import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { createRoutes } from "../@crema/utility/Utils";
//import {dashBoardConfigs} from './dashboard';
import { muiComponentConfigs } from "./muiComponents";
//import {thirdPartyConfigs} from './thirdParty';
//import {userPagesConfig} from './userPages';
//import {appsConfig} from './apps';
//import {extraPagesConfigs} from './extraPages';
//import {userListConfig} from './userList';
import { errorPagesConfigs } from "./errorPages";
//import {ecommerceConfig} from './ecommerce';
import { authRouteConfig } from "./auth";
import { initialUrl } from "../shared/constants/AppConst";
import { menuLevelConfig } from "./menu";
import { recordingsConfigs } from "./recordings";
import { rolesConfigs } from "./roles";
import { devicesConfigs } from "./hosts";
import { usersConfigs } from "./users";
import { groupsConfigs } from "./groups";
import { hostsDashboardConfigs } from "./hostsdashboard";
import { settingsConfigs } from "./settings";
import { ssoConfigs } from "./sso";
import { registrationKeysConfigs } from "./registrationKeys";

const routeConfigs = [
  ...authRouteConfig,
  //...dashBoardConfigs,
  ...muiComponentConfigs,
  //...thirdPartyConfigs,
  //...userPagesConfig,
  //...appsConfig,
  //...userListConfig,
  //...extraPagesConfigs,
  ...errorPagesConfigs,
  //...ecommerceConfig,
  ...menuLevelConfig,
  ...recordingsConfigs,
  ...rolesConfigs,
  ...devicesConfigs,
  ...usersConfigs,
  ...groupsConfigs,
  ...hostsDashboardConfigs,
  ...settingsConfigs,
  ...ssoConfigs,
  ...registrationKeysConfigs,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    path: "/sso/token",
    exact: true,
    component: () => <Redirect to={"/sso"} />,
  },
  {
    path: "/saml/acs",
    exact: true,
    component: () => <Redirect to={"/sso"} />,
  },
  {
    path: "/ssoauth",
    exact: true,
    component: () => <Redirect to={"/sso"} />,
  },
  {
    component: () => <Redirect to="/error-pages/error-404" />,
  },
];

export default routes;
