import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    onTimerElapsed,
} from '../../../redux/actions';

// Global Timer Component.  Creates a timer globally in the app that all views can use
const TimerComponent = () => {
    const dispatch = useDispatch();
    let updateLiveSessionsDurationTimer: any = null;

    useEffect(() => {
        
        updateLiveSessionsDurationTimer = setInterval(() => {
            dispatch(onTimerElapsed());
        }, 300 * 1000); // refresh the active view every 5 minutes
        
        return () => {
            // Anything in here is fired on component unmount.
            clearInterval(updateLiveSessionsDurationTimer);
        }
    }, [])
    
    return null
}

export default TimerComponent;
