import React, {useState} from 'react';
import {Dialog, Link} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import IntlMessages from '../../../@crema/utility/IntlMessages';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import TextField from '@material-ui/core/TextField';

const ChangePasswordDialog = ({open, onDeny, onConfirm, title, dialogTitle}) => {

  const [userValues, setUserValues] = React.useState({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const handleChange = (name) => (event) => {
    setUserValues({...userValues, [name]: event.target.value});
  };

  const useStyle = makeStyles({
    btn: {
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
    },
    contentText: {
      color: grey[600],
    },
    textField: {
      width: '240px',
    }
  });
  const classes = useStyle();
  const history = useHistory();


  const mismatchedPasswords = () => {
    return userValues.newPassword != userValues.newPasswordConfirm;
  }

  const invalidPasswordLength = () => {
    return !userValues.newPassword || !userValues.newPasswordConfirm || userValues.newPassword.length < 8 || userValues.newPasswordConfirm.length < 8;
  }

  return (
    <Dialog open={open} onClose={() => onDeny(false)}>
      <Box width={300} px={{xs: 5, md: 7}} pt={{xs: 4, md: 6}} pb={{xs: 2, md: 4}}>
        <Box
          mb={3}
          component='h4'
          fontWeight={Fonts.MEDIUM}
          className='font-bold'
          id='alert-dialog-title'>
          {dialogTitle}
        </Box>
        <Box>
          <DialogContentText
            className={classes.contentText}
            id='alert-dialog-description'>
            {title}
          </DialogContentText>

          {/*<form className={classes.container} noValidate autoComplete='off'>*/}
          <br/>
          {invalidPasswordLength() &&
            <Box color = "red"> {<IntlMessages id='user.InvalidPasswordLength' />} </Box>
          }
          {mismatchedPasswords() &&
          <Box color = "red"> {<IntlMessages id='user.PasswordsDoNotMatch' />} </Box>
          }

          <Box>
            <TextField
              id='new-password'
              label='New Password'
              type="password"
              className={classes.textField}
              autoFocus
              required={true}
              value={userValues.newPassword}
              onChange={handleChange('newPassword')}
              margin='normal'
              onFocus={event => {
                event.target.select();
              }}
            />
          {/* </form> */}
          </Box>
          <Box>
            <TextField
              id='new-password-confirm'
              label='Confirm New Password'
              type="password"
              className={classes.textField}
              required={true}
              value={userValues.newPasswordConfirm}
              onChange={handleChange('newPasswordConfirm')}
              margin='normal'
              onFocus={event => {
                event.target.select();
              }}
            />
          </Box>

          <br/>
          {!userValues.password && 
            <Box color = "red"> {<IntlMessages id='user.YourPasswordIsRequiredInOrderToChangeThis' />} </Box>
          }
          <Box>
            <TextField
              id='standard-password'
              helperText='To allow this change'
              label='Your Current Password'
              type="password"
              className={classes.textField}
              required={true}
              value={userValues.password}
              onChange={handleChange('password')}
              margin='normal'
              onFocus={event => {
                event.target.select();
              }}
            />
          </Box>
          {/*
          <Link onClick={()=> {
              history.push('/forget-password', {tab: 'jwtAuth'}) // show forgot password UI
              onDeny(false); // close the dialog
            }
          }>Forgot Password</Link>
          */}

        <br/>

        {/* </form> */}

        </Box>

        <Box pt={2} align='right'>
          <Button
            className={classes.btn}
            onClick={() => onDeny(false)}
            color='secondary'>
            <IntlMessages id='common.cancel' />
          </Button>
          <Button
            disabled={!userValues.password || !userValues.newPassword || !userValues.newPasswordConfirm || mismatchedPasswords() || invalidPasswordLength()}
            className={classes.btn}
            onClick={() => onConfirm(userValues)}
            color='primary'>
            <IntlMessages id='common.save' />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ChangePasswordDialog;

