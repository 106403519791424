import axios from "axios";

const apiAxios = axios.create({
  baseURL: '',
  //baseURL: '',
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
apiAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers["Authentication-Token"] = token;

  return config;
});

export default apiAxios;
